<template>
	<div class="order_wrap">
		<v-container class="">
			<MStepper />
			<h2 class="tit_page">계약확인</h2>
			<iframe class="sample_iframe" src="" frameborder="0"></iframe>
			<div class="bottom_box">
				<!-- <v-btn color="" class="btn_col2">
					이전
				</v-btn> -->
				<v-btn color="primary" class="btn_col2">
					전자서명하기
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			dialog: false,
			toggle_exclusive: 1,
			dialogVisible: false
		}
	},
	methods: {
		open() {
			this.dialogVisible = true
		}
	}
}
</script>

<style scoped lang="scss"></style>
